import React, { useState } from 'react';
import './CreateAssignmentModal.css';
import { useAssignment } from '../../hooks/useAssignment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function CreateAssignmentModal({ trainerId, studentId, isOpen, onClose }) {
  const { assignment, isLoading } = useAssignment();
  const [assignmentTitle, setassignmentTitle] = useState("");
  const [assignmentDescription, setassignmentDescription] = useState("");
  const [submissionDeadline, setsubmissionDeadline] = useState("");
  const [assignmentLink, setassignmentLink] = useState("");
  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(assignmentTitle, assignmentDescription, submissionDeadline, assignmentLink,studentId);
    assignment({
      assignment_title: assignmentTitle,
      assignment_description: assignmentDescription,
      submission_deadline: submissionDeadline,
      assignment_link: assignmentLink,
      studentId,
      trainerId
    },{onSettled: (data) => {
      onClose();
    }});

  };

  return (
    <div className="assignment-modal assignment-modal-show">
      <div className="assignment-modal-content">
        <span style={{ cursor: 'pointer' }} className="assignment-modal-close-button" onClick={onClose}>
          &times;
        </span>
        <form>
          <div className="assignment-form-group">
            <label htmlFor="assignment-title">Assignment Title</label>
            <input name='assignment_title' type="text" id="assignment-title" placeholder="Enter title" onChange={(e) => setassignmentTitle(e.target.value)} />
          </div>

          <div className="assignment-form-group">
            <label htmlFor="assignment-description">Assignment Description</label>
            <textarea name='assignment_description' id="assignment-description" placeholder="Enter description" onChange={(e) => setassignmentDescription(e.target.value)} ></textarea>
          </div>

          <div className="assignment-form-group">
            <label htmlFor="submission-deadline">Submission Deadline</label>
            <input type="date" id="submission-deadline" name='submission_deadline' onChange={(e) => setsubmissionDeadline(e.target.value)} />

            {/* <DatePicker
              minDate={new Date()} 
              dateFormat="dd/MM/yyyy"
              placeholderText="Choose a date"
              className="custom-datepicker-input"
            /> */}
          </div>
          
          <div className="assignment-form-group">
            <label htmlFor="assignment-link">Assignment Link</label>
            <input name='assignment_link' type="url" id="assignment-link" placeholder="Paste Your Link" onChange={(e) => setassignmentLink(e.target.value)} />
          </div>


          <div className="assignment-form-actions">
            <button onClick={handleSubmit} type="submit">Submit</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateAssignmentModal;
