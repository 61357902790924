import React from 'react'
import './AssignmentManagement.css'
import AssignmentManagementMain from '../../components/AssignmentManagementMain/AssignmentManagementMain'
import { Sidebar } from '../../components/Sidebar/Sidebar'
import { useSearchParams } from 'react-router-dom';

function AssignmentManagement() {
  const [searchParams] = useSearchParams();
  const studentId = searchParams.get('id');
  
  return (
    <div style={{display:'flex'}} className='assignment-management-container'>
        <Sidebar />
        <AssignmentManagementMain studentId={studentId}  />
    </div>
  )
}

export default AssignmentManagement