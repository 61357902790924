import React from 'react'
import './AssignmentViewDetails.css'
import { Sidebar } from '../../components/Sidebar/Sidebar'
import AssignmentViewDetailsInner from '../../components/AssignmentViewDetailsInner/AssignmentViewDetailsInner'
import { useSearchParams } from 'react-router-dom';

function AssignmentViewDetails() {
  const [searchParams] = useSearchParams();
  const assignmentId = searchParams.get('id');
  return (
    <div style={{display:'flex'}} className='assignment-view-details-container'>
          <Sidebar />
          <AssignmentViewDetailsInner assignmentId={assignmentId} />
    </div>
  
  )
}

export default AssignmentViewDetails