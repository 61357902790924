import React, { useState } from 'react';
import './AssignmentViewDetailsInner.css';
import { IoMdArrowRoundBack } from 'react-icons/io';

function AssignmentViewDetailsInner({assignmentId}) {
  const students = [
    { id: 1, name: 'Saikat Chakraborty', assignmentLink: '/path/to/assignment1', grade: '' },
  ];

  const [grades, setGrades] = useState({});

  // Handle grade input change with validation
  const handleGradeChange = (e, studentId) => {
    const value = e.target.value;
    if (value >= 0 && value <= 10) {
      setGrades({ ...grades, [studentId]: value });
    }
  };

  // Handle grade submission
  const handleGradeSubmit = (studentId) => {
    const grade = grades[studentId];
    if (grade !== undefined) {
      console.log(`Grade for student ${studentId} submitted: ${grade}`);
      // Submit grade logic here
    } else {
      alert('Please enter a valid grade before submitting.');
    }
  };

  return (
    <div className="assignment-view-details-inner-container">
      
      
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-2.5rem', width: '55%' }} className="assignment-view-details-inner-header">
      <div
              style={{ cursor: "pointer", fontSize: "2rem", marginTop: "2rem" }}
              className="back-button"
              onClick={() => window.history.back()}
            >
              <IoMdArrowRoundBack />
            </div>
      
        <p>Assignment Details</p>
      </div>
      <div className="assignment-view-details-inner-content">
        <div className="assignment-view-details-inner-content-description">
          <p>{}</p>
        </div>

        {/* Table to display students, assignment view/download, grading */}
        <div className="view-student-assignment-table">
          <table className="student-assignment-table">
            <thead>
              <tr>
                <th>Student Name</th>
                <th>View/Download Assignment</th>
                <th>Grade (Out of 10)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.id}>
                  <td>{student.name}</td>
                  <td>
                    <a href={student.assignmentLink} target="_blank" rel="noopener noreferrer">
                      View/Download
                    </a>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      max="10"
                      value={grades[student.id] || ''}
                      onChange={(e) => handleGradeChange(e, student.id)}
                      placeholder="Enter grade"
                    />
                  </td>
                  <td>
                    <button onClick={() => handleGradeSubmit(student.id)}>
                      Submit Grade
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AssignmentViewDetailsInner;
