import React, { useState, useRef, useEffect } from "react";
import UnitCards from "../UnitCards/UnitCards";
import "./UnitCardContainer.css";
import fullscreenIcon from "../../assets/images/fullscreen-icon.png";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/constant";

function UnitCardContainer() {
  const [units, setUnits] = useState([]);
  const [scormUrl, setScormUrl] = useState(
    "https://courses.accentaura.com/A1/A1_Unit-1/"
  );
  const [unitDescription, setUnitDescription] = useState(
    "Unit 1: Introduction to Accent Aura"
  );
  const iframeRef = useRef(null);
  const { courseid } = useParams();

  // Carousel state
  const [currentIndex, setCurrentIndex] = useState(0);
  const cardsPerPage = 3; // Number of cards to show per slide

  // Function to handle card clicks and update the iframe source and description
  const handleCardClick = (url, description) => {
    setScormUrl(url);
    setUnitDescription(description);
  };

  const handleFullscreen = () => {
    if (iframeRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        iframeRef.current.requestFullscreen();
      }
    }
  };

  const nextSlide = () => {
    if (currentIndex + cardsPerPage < units.length) {
      setCurrentIndex(currentIndex + cardsPerPage);
    }
  };

  const prevSlide = () => {
    if (currentIndex - cardsPerPage >= 0) {
      setCurrentIndex(currentIndex - cardsPerPage);
    }
  };

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const uri = `${API_BASE_URL}course/get-units/${courseid}`;
        const units = await fetch(uri, {
          method: "GET",
          credentials: "include",
        });
        let data = await units.json();
        data.units.sort((a, b) => a.id - b.id);
        setUnits(data?.units);
        if (data?.units?.length > 0) {
          setScormUrl(data?.units[0].unit_link);
          setUnitDescription(data?.units[0].title);
          console.log(data, "data");
        }
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };

    fetchUnits();
  }, [courseid]);

  return (
    <div className="unit-card-container">
      <div className="iframe-wrapper">
        <iframe
          ref={iframeRef}
          src={scormUrl}
          height="600px"
          allowFullScreen
          frameBorder="0"
          title="SCORM Package Content"
        />
        <img
          src={fullscreenIcon}
          alt="fullscreen-icon"
          className="fullscreen-icon"
          onClick={handleFullscreen}
        />
      </div>

      {/* Unit description section */}
      <div className="unit-card-long">
        <p className="unit-desc-title">{unitDescription}</p>
      </div>

      {/* Carousel Section */}
      <div className="carousel-container">
        <button
          className="prev-button"
          onClick={prevSlide}
          disabled={currentIndex === 0}
        >
          &#10094;
        </button>

        <div className="unit-cards-section">
          {units
            .slice(currentIndex, currentIndex + cardsPerPage)
            .map((unit) => (
              <UnitCards
                key={unit.id}
                unitNumber={unit.id}
                unitTitle={unit.title}
                onCardClick={() => handleCardClick(unit.unit_link, unit.title)}
              />
            ))}
        </div>

        <button
          className="next-button"
          onClick={nextSlide}
          disabled={currentIndex + cardsPerPage >= units.length}
        >
          &#10095;
        </button>
      </div>
    </div>
  );
}

export default UnitCardContainer;
