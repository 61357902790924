import { useQuery } from "@tanstack/react-query";
import { getCurrTrainer } from "../config/restApiAuth";
import { useQueryClient } from "@tanstack/react-query";

export function useCurrentTrainer() {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(["user"]);
  console.log(user, "user");
  const email = user?.user.email;
  const {
    isLoading,
    data: trainers,
    error,
  } = useQuery({
    queryKey: ["CurrTrainer"],
    queryFn: () => getCurrTrainer({ trainerId: email }),
    retry: true,
    // refetchInterval: 30 * 1000,
  });

  return {
    isLoading,
    error,
    schedules: trainers?.trainers[0].classes,
    name: trainers?.trainers[0].name,
  };
}