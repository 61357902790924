import React, { useState } from "react";
import "./AssignmentManagementMain.css";
import CreateAssignmentBanner from "../CreateAssignmentBanner/CreateAssignmentBanner";
import AssignmentLibraryCard from "../AssignmentLibraryCard/AssignmentLibraryCard";
import Pagination from "../Pagination/Pagination";
import AssignmentSearchBar from "../AssignmentSearchBar/AssignmentSearchBar";
import { IoMdArrowRoundBack } from "react-icons/io";
import CreateAssignmentModal from "../CreateAssignmentModal/CreateAssignmentModal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCurrentTrainer } from "../../hooks/useCurrentTrainer";
import { getAssignments } from "../../config/restApiAuth";

function AssignmentManagementMain({ studentId }) {
  const [isModalOpen, setModalOpen] = useState(false);

  const queryClient = useQueryClient();

  const { isLoading: isTrainerLoading, name } = useCurrentTrainer();
  const trainerDetails = queryClient.getQueryData(["CurrTrainer"]);
  const trainerId = trainerDetails?.trainers[0]?._id;
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const cardsPerPage = 6;
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;

  // Filter assignments based on search term

  // Get current assignments based on pagination

  const { isLoading: assignmentsLoading, data: assignments } = useQuery({
    queryKey: ["assignments"],
    queryFn: () => getAssignments({ studentId }),
  });

  const filteredAssignments = assignments?.assignments?.filter((assignment) =>
    assignment.assignment_title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const currentAssignments = filteredAssignments?.slice(
    indexOfFirstCard,
    indexOfLastCard
  );

  return (
    <div className="assignment-management-main-section">
      <div className="assignment-management-main-container">
        <div className="assignment-management-main-heading">
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }} className="assignment-management-main-heading-line">
            <div
              style={{ cursor: "pointer", fontSize: "2rem", marginBottom: "-1.2rem" }}
              className="back-button"
              onClick={() => window.history.back()}
            >
              <IoMdArrowRoundBack />
            </div>

            <p>Assignment Management</p>
          </div>

          <div className="profile-notification-container">
            {/* <span className="profile-icon material-symbols-outlined">
              account_circle
            </span> */}
            <p className="bell-icon material-symbols-outlined">notifications</p>
          </div>
        </div>

        {/* Create Assignment Banner */}
        <div className="assignment-management-main-create-banner">
          <CreateAssignmentBanner onClick={openModal} />
        </div>

        <CreateAssignmentModal
          trainerId={trainerId}
          studentId={studentId}
          isOpen={isModalOpen}
          onClose={closeModal}
        />

        {/* Search Bar */}
        <div className="assignment-management-main-search-bar">
          <AssignmentSearchBar onSearch={setSearchTerm} />
        </div>

        {/* Assignment Library */}
        <div className="assignment-management-main-assignment-library">
          <div className="assignment-management-main-assignment-library-heading">
            <p>Assignment Library</p>
          </div>

          {/* Assignment Cards */}
          <div className="assignment-management-main-assignment-library-card-container">
            {currentAssignments?.length > 0 ? (
              currentAssignments?.map((assignment) => (
                <AssignmentLibraryCard
                  key={assignment._id}
                  studentId={studentId}
                  assignment={assignment}
                />
              ))
            ) : (
              <p>No Assignments Found</p>
            )}
          </div>

          {/* Pagination */}
          <Pagination
            totalCards={filteredAssignments?.length}
            cardsPerPage={cardsPerPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default AssignmentManagementMain;
