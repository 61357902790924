import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { userLogin } from "../../config/restApiAuth";
import { toast } from "react-hot-toast";

export function useLogin() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const mutate = useMutation({
    mutationFn: ({ email, password }) => userLogin({ email, password }),
    onSuccess: (user) => {
      queryClient.setQueryData(["user"], user);
      if (!user.success) {
        toast.error("Invalid credentials");
      } else {
        toast.success("Login Successful");
        navigate("/trainerdashboard");
      }
    },
    onError: (error) => {
      // console.log(error);
    },
  });
  let { mutate: login, isPending: isLoading } = mutate;
  return { login, isLoading };
}
