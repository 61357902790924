import { useMutation } from "@tanstack/react-query";
import { createAssignment } from "../hooks/apiAssignment";
import toast from "react-hot-toast";

export function useAssignment() {
  const { mutate: assignment, isLoading } = useMutation({
    mutationFn: createAssignment,
    onSuccess: () => {
      toast.success("Assignment created successfully");
    }

  });

  return { assignment, isLoading };
}

