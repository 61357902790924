import React, { useState, useEffect } from "react";
import "./AssignedCourse.css";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import AssignedCourseCard from "../../components/AssignedCourseCard/AssignedCourseCard";
import { API_BASE_URL } from "../../config/constant";

function AssignedCourse() {
  const [courses, setCourses] = useState([]); // State for storing fetched courses

  // Fetch courses data from supabase
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const uri = `${API_BASE_URL}course/get-courses`;
        const units = await fetch(uri, {
          method: "GET",
          credentials: "include",
        });
        let data = await units.json();
        data?.course?.sort((a, b) => a.id - b.id);
        setCourses(data?.course); // Set the data in state
      } catch (error) {
        console.error("Error fetching course:", error);
      }
    };

    fetchCourses();
  }, []);

  return (
    <div className="assigned-course-section">
      <Sidebar />
      <div className="assigned-course-container">
        <div className="assigned-course-container-heading heading-bell-container">
          <h1>Courses Assigned</h1>
          <div className="profile-notification-container">
            <p className="bell-icon material-symbols-outlined">notifications</p>
          </div>
        </div>
        <div className="assigned-course-card-container">
          {courses?.map((course) => (
            <AssignedCourseCard
              key={course.course_id}
              id={course.course_id}
              title={course.title}
              description={course.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AssignedCourse;
